<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <span *ngIf="mode == 1">Assigned Team For Job :- {{job.code}} </span>
      <span *ngIf="mode == 2">Assign Team For Job</span>

    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(0)">
      <span aria-hidden="true">&times;</span>
  
  
    </button>
  </div>
  <div class="modal-body" *ngIf="isNew || mode ==2">
    <form [formGroup]="masterForm"  >
      <div class="form-row">

          <div class="col-md-5 mb-3" *ngIf="mode == 1">
            <label  for="teamId">{{'Team' | translate}}
              <span class="validator-star">*</span>
            </label>
            <ng-select [items]="teamsList" placeholder="Select Team" (change)="onChange()"
            bindLabel="teamName"
            bindValue="id" 
              class="js-example-placeholder-multiple" formControlName="teamId"></ng-select>
            <span class="custom-validation-messages" *ngIf="submitted && f.teamId.errors" >
              <div *ngIf="f.teamId.errors.required" class="validation-message">Please provide a valid Team.</div>
            </span>
          </div>
        
          <div class="col-md-1 mb-3" *ngIf="mode == 1">
            <br>
            <button style="margin-top: 10px" (click)="onTeamChange()">
              +
            </button>
            </div>


          <div class="col-md-5 mb-3" *ngIf="mode ==2">
            <label  for="jobId">{{'Job' | translate}}
              <span class="validator-star">*</span>
            </label>
            <ng-select [items]="jobList" placeholder="Select Job"  (keyup)="getAllJobs($event.target.value)" (change)="getAssignedTeams();"
            bindLabel="code"
            bindValue="id" 
              class="js-example-placeholder-multiple" formControlName="jobId">
              <ng-template ng-option-tmp let-item="item">
                <div title="{{item.code}}">{{item.code}}</div>
                </ng-template></ng-select>
            <span class="custom-validation-messages" *ngIf="submitted && f.jobId.errors" >
              <div *ngIf="f.jobId.errors.required" class="validation-message">Please provide a valid job.</div>
            </span>
          </div>

          <div class="col-md-1 mb-3" *ngIf="mode == 2">
            <br>
            <button title="assigned teams" style="margin-top: 10px" (click)="showAssignedTeamsForJob()">
              +
            </button>
            </div>


         

        <div class="col-md-6 mb-3">
            <label  for="description">{{'Description' | translate}}
              <!-- <span class="validator-star">*</span> -->
            </label>
            <input class="form-control" id="description" type="text"  formControlName="description" >
            <span class="custom-validation-messages" *ngIf="submitted && f.description.errors" >
              <div *ngIf="f.description.errors.required" class="validation-message">Please provide a valid description.</div>
            </span>
          </div>


          <div class="col-md-6 mb-3">
            <label  for="assignedFor">{{'Assigned For' | translate}}
              <!-- <span class="validator-star">*</span> -->
            </label>
            <input type="date" class="form-control" formControlName="assignedFor" placeholder="yyyy-mm-dd" name="dp"  >
            <!-- <input class="form-control" id="assignedFor" type="text"  formControlName="assignedFor" > -->
            <span class="custom-validation-messages" *ngIf="submitted && f.assignedFor.errors" >
              <div *ngIf="f.assignedFor.errors.required" class="validation-message">Please provide a valid assignedFor.</div>
            </span>
          </div>


          <!-- <div class="col-md-6 mb-3">
            <label  for="vehicleId">{{'Vehicle' | translate}}
            </label>
            <ng-select [items]="vehicleList" placeholder="Select Vehicle" 
            bindLabel="vehicleNo"
            bindValue="id" 
              class="js-example-placeholder-multiple" formControlName="vehicleId" [multiple]="true"></ng-select>
            <span class="custom-validation-messages" *ngIf="submitted && f.vehicleId.errors" >
              <div *ngIf="f.vehicleId.errors.required" class="validation-message">Please provide a valid vehicle.</div>
            </span>
          </div> -->

          <!-- <div class="col-md-6 mb-3">
            <label  for="isDriver">&nbsp;
              <span class="validator-star"></span>
            </label>
              <label class="d-block" for="isDriver">
                <input class="checkbox_animated" formControlName="isDriver" id="isDriver" type="checkbox" checked=""> Driver
              </label>
            </div>


            <div class="col-md-6 mb-3">
                <label  for="isTeamLeader">&nbsp;
                  <span class="validator-star"></span>
                </label>
                  <label class="d-block" for="isTeamLeader">
                    <input class="checkbox_animated" formControlName="isTeamLeader" id="isTeamLeader" type="checkbox" checked=""> Team Leader
                  </label>
                </div> -->
  
      </div>

      <!-- <div class="form-row">
        <button class="btn btn-primary mr-1" (click)="push()">
            <span>Push</span>
          </button>
      </div>
      <div class="form-row">
          &nbsp;
      </div> -->
     
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table" style="min-height: 200px">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Staff Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Driver</th>
                  <th scope="col">Leader</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selectedStaffs; let i = index;">
                  
                  <td scope="row">{{item.staffName}}</td>
                  <td>{{item?.description}}</td>
                  <td>

                    <div class="form-check checkbox checkbox-primary mb-0">
                      <input class="form-check-input" id="checkbox-primary-1{{i}}" [(ngModel)]="item.isDriver" [ngModelOptions]="{standalone: true}" type="checkbox" />
                      <label class="form-check-label" for="checkbox-primary-1{{i}}"></label>
                    </div>



                    <!-- <input class="checkbox_animated"  [(ngModel)]="item.isDriver" [ngModelOptions]="{standalone: true}"  id="isDriver" type="checkbox" >  -->

                    <!-- <input class="checkbox_animated" (change)="onChangeCheckBox(0,item.staffId , item.isDriver)" [(ngModel)]="item.isDriver" [ngModelOptions]="{standalone: true}"  id="isDriver" type="checkbox" >  -->
                  </td>
                  <td>
                    <!-- <input class="checkbox_animated"  (change)="onChangeCheckBox(1,item.staffId , item.isTeamLeader)"  [(ngModel)]="item.isTeamLeader" [ngModelOptions]="{standalone: true}" id="isTeamLeader" type="checkbox" >  -->
                    <div class="form-check checkbox checkbox-primary mb-0">
                      <input class="form-check-input" id="checkbox-primary-2{{i}}" (change)="onChangeCheckBox(1,item.staffId , item.isTeamLeader)"  [(ngModel)]="item.isTeamLeader" [ngModelOptions]="{standalone: true}"  type="checkbox" />
                      <label class="form-check-label" for="checkbox-primary-2{{i}}"></label>
                    </div>

                </td>
                

                <td>
                  <ng-select *ngIf="item.isDriver == true" [items]="vehicleList" placeholder="Select Vehicle" 
                  bindLabel="vehicleNo"
                  bindValue="id" 
                    class="js-example-placeholder-multiple" [(ngModel)]="item.vehicleId" [ngModelOptions]="{standalone: true}" ></ng-select>
      
              </td>


                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
     
    </form>



   


  
  
  
  
  </div>


  <div class="card-body">
    <div class="card-block row" *ngIf="!isNew && mode == 1">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table">
              <thead class="thead-dark">
                <tr>
                    <th *ngIf="!unAttendedMode"></th>
                  <th scope="col">Team Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Assigned For</th>
                  <th scope="col">Vehicle No</th>
                  <th></th>

              </thead>
              <tbody>
                <tr *ngFor="let item of assignedTeams; let i = index;">
                    <th scope="row" *ngIf="!unAttendedMode">


                        
                                                <span class="header-row-icons">
                                                    <i class="fa fa-trash-o row-delete" (click)="onDelete(item)"></i>
                                                </span>
                                                <!-- <span class="header-row-icons" style="padding-left: 10px">
                                                    <i class="fa fa-edit row-edit" (click)="onEdit(item)"></i>
                                                </span> -->
                                               
                                              </th>
                  <td scope="row">{{item.teamName}}</td>
                  <td>{{item?.description}}</td>
                  <td>{{item?.assignedFor}}</td>
                  <td>
                    {{item?.vehicleName}}
                    <!-- <ng-contaier *ngFor="let element of item.jobVehicles">
                      {{element?.vehicleName}} &nbsp; &nbsp;&nbsp;
                    </ng-contaier> -->
                    </td>
                  <td>
                 
                    <span class="header-row-icons">
                      <i class="fa fa-bell row-delete" (click)="onSendNotifications(item)" alt="send notifications"></i>
                  </span>
                    </td>

               
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
 
  <div class="modal-footer">
    <button class="btn btn-primary mr-1" (click)="onSubmit()" *ngIf="isNew || mode == 2">
      <span>Save </span>
    </button>
    <button style="float: left !important" class="btn btn-primary mr-1" (click)="isNew = true" *ngIf="!isNew && mode == 1 && !unAttendedMode">
      <span>Add New</span>
    </button>
    <button (click)="modal.dismiss(0)" class="btn btn-secondary" *ngIf="!isNew">Close</button>
    <button (click)="isNew = false;" class="btn btn-secondary" *ngIf="isNew">Reset</button>

  
  </div>