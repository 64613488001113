import { DatePipe } from '@angular/common';
import { Component , Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { apiUrl } from 'src/app/shared/constants/apiUrl-constants';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-alerts-popup',
  templateUrl: './alerts-popup.component.html',
  styleUrls: ['./alerts-popup.component.scss']
})
export class AlertsPopupComponent {
  search : string ='';
  p : number =1;
  totalCount : number = 1;
  itemsPerPage : number = 10;
  public resultList : Array<any> = [];
  public searchList : Array<any> = [];

  @Input() mode : number =0;


  constructor(public modal : NgbActiveModal, 
    private datePipe : DatePipe,
    private router : Router,
    private http : HttpService) { }

  ngOnInit(): void {


    this.getData();

  }

  
  getData() {
 

   
    
    let body ={
      pageIndex : this.p,
      pageSize : this.itemsPerPage
    }
    this.http.postApi(apiUrl.GetDashboardWarnings, body).subscribe((resdata:any) =>{
      if(resdata.success == true){
        this.resultList = resdata.result.items;
        this.searchList = resdata.result.items;
        if(resdata.result.items.length > 0){
          this.totalCount = resdata.result.totalCount;
          }
          else {
            this.totalCount = 1;
          }
      }
      })
    }

    transform(items: any[], criteria: any): any {
      if(criteria != undefined && items != undefined){
      this.searchList= items.filter(item =>{
         for (let key in item ) {
          if((""+item[key]).toLocaleLowerCase().includes(criteria.toLocaleLowerCase())){
             return true;
         }
         }
          return false;
      });
    }}
  
  


}