<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <span >Assigned Teams </span>
    
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(0)">
      <span aria-hidden="true">&times;</span>
  
  
    </button>
  </div>
  <div class="modal-body" >
    <div class="card-body">
        <div class="card-block row" >
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-dark">
                    <tr>
                        <th></th>
                      <th scope="col">Team Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Assigned For</th>
                      <th scope="col">Vehicle No</th>
    
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of assignedTeams; let i = index;">
                        <th scope="row">
    
    
                            
                                                    <span class="header-row-icons">
                                                        <i class="fa fa-trash-o row-delete" (click)="onDelete(item)"></i>
                                                    </span>
                                                    <!-- <span class="header-row-icons" style="padding-left: 10px">
                                                        <i class="fa fa-edit row-edit" (click)="onEdit(item)"></i>
                                                    </span> -->
                                                   
                                                  </th>
                      <td scope="row">{{item.teamName}}</td>
                      <td>{{item?.description}}</td>
                      <td>{{item?.assignedFor}}</td>
                      <td>
                        {{item?.vehicleName}}
                        <!-- <ng-contaier *ngFor="let element of item.jobVehicles">
                          {{element?.vehicleName}} &nbsp; &nbsp;&nbsp;
                        </ng-contaier> -->
                        </td>
    
                   
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </div>

    
  <div class="modal-footer">
    <button (click)="modal.dismiss(0)" class="btn btn-secondary" >Close</button>
  </div> 