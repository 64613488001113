import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor(private http : HttpService ) {

  }


  public getAll(url : string) : Observable<any>{
  return this.http.getApi(url).pipe(map((response) =>{
    return response;
  }))
  }
  public getById(url : string) : Observable<any>{
  return this.http.getApi(url).pipe(map((response) =>{
    return response;
  }))
  }
  public saveData(url : string , requestObj) : Observable<any>{
  return this.http.postApi(url , requestObj).pipe(map((response) =>{
    return response;
  }))
  }
  public updateData(url : string , requestObj) : Observable<any>{
    return this.http.putApi(url , requestObj).pipe(map((response) =>{
      return response;
    }))
    }
  public deleteData(url : string) : Observable<any>{
  return this.http.deleteApi(url).pipe(map((response) =>{
    return response;
  }))
  }

}
