import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { apiUrl } from 'src/app/shared/constants/apiUrl-constants';
import { HttpService } from 'src/app/shared/services/http.service';
import { TeamStaffMappingComponent } from '../../teams/team-staff-mapping/team-staff-mapping.component';
import { ITeams } from '../../teams/teams.model';
import { AssignedTeamsByJobComponent } from '../assigned-teams-by-job/assigned-teams-by-job.component';
import { IJobs } from '../jobs.model';

@Component({
  selector: 'app-assign-job-team',
  templateUrl: './assign-job-team.component.html',
  styleUrls: ['./assign-job-team.component.css']
})
export class AssignJobTeamComponent implements OnInit {




  isNew : boolean = false;

  @Input() unAttendedMode : boolean = false;


  @Input() job : IJobs ;
  @Input() team : ITeams ;

  @Input() mode : number ;

  selectedStaffs :Array<any> = []
  teamsList : Array<ITeams> = []  ;
  assignedTeams :Array<any> = []

  masterForm : FormGroup;
  jobList : Array<IJobs>= [];

  constructor(public modal : NgbActiveModal , private httpService : HttpService ,
    private modalService : NgbModal,
    private datePipe : DatePipe,
    private toastr : ToastrService,
    private fb : FormBuilder
    ) { }

  ngOnInit(): void {
    this.getTeams();
    this.buildForm();
 

    if(this.mode == 2){
    this.getAllJobs("");

    }
    this.getAllVehicles();

  }

  showAssignedTeamsForJob(){

    if(this.f.jobId.value == 0 || this.f.jobId.value == undefined || this.f.jobId.value == null){
      return;
    }
    const dialogRef = this.modalService.open(AssignedTeamsByJobComponent, {
      centered: true ,
      size :'lg'
   });
   dialogRef.componentInstance.jobId =  this.f.jobId.value;

   dialogRef.result.then(res =>{
    if(res == 1){
      // this.getAllJobs();
    }
  })
  }
  getAllJobs(text : string){
    let body  = {
        filterText : text,
        pageIndex : 1,
        pageSize : 10
    }
    this.httpService.postApi(apiUrl.JobsGetAllForDropwDown , body).subscribe((resdata:any) =>{
      this.jobList = resdata.result.items ;
    })
  }

  onTeamChange(){
    if(this.f.teamId.value > 0){
      const dialogRef = this.modalService.open(TeamStaffMappingComponent, {
        centered: true ,
        size :'lg'
     });
     dialogRef.componentInstance.team =  this.teamsList.find(x => x.id == this.f.teamId.value);
     dialogRef.result.then(res =>{
      if(res == 1){
        this.onChange();
      }
    })
    }
  }


  onSendNotifications(item : any){
    if(confirm('are you sure to send reminder for team')){
      let body = {
        jobId : this.job.id,
        teamId : item.teamId,
        startdt: item.assignedFor
      }
      this.httpService.postApi('JobStaffMappings/SendPushNotificationsForTeam' , body).subscribe((resdata: any) => {
        if(resdata.success == true){
         this.toastr.success('Reminders sent succesfully');
        }
      })
    }
  }
  onDelete(item : any){
    if(confirm('are you sure to remove this team')){
      let body = {
        jobId : this.job.id,
        teamId : item.teamId,
        assignedFor:item.assignedFor
      }
      this.httpService.postApi('JobStaffMappings/DeleteAssignedTeam' , body).subscribe((resdata: any) => {
        if(resdata.success == true){
      this.toastr.success('Deleted Successfully');
       this.getAssignedTeams();
        }
      })
    }
  }
  getAssignedTeams(){
    this.httpService.getApi('JobStaffMappings/GetAssignedTeams/' + this.f.jobId.value).subscribe((resdata:any) =>{
      this.assignedTeams = resdata.result;
      if(this.assignedTeams.length == 0){
        this.isNew = true;
      }
    })
  }


  onChangeCheckBox(key : number , id : number , value : boolean){
    // alert(value)
        if(key == 0 && value == true){
          // 
           this.selectedStaffs.forEach(x =>{
            if(x.staffId != id){
              x.isDriver = false;
            }
           })
        }
        if(key == 1 && value == true){
           this.selectedStaffs.forEach(x =>{
            if(x.staffId != id){
              x.isTeamLeader = false;
            }
           })
        }
  }

  vehicleList : Array<any>=[]

  getAllVehicles(){
    
    this.httpService.getApi(apiUrl.VehiclesGetAllForDropwDown).subscribe((resdata:any) =>{
      this.vehicleList = resdata.result ;
    })
  }


  onSubmit(){

    this.submitted = true;
    if(this.masterForm.invalid){
      return;
    }


    // console.log(this.f.vehicleId.value.length)

    // let length = 0
    // if(this.f.vehicleId.value.length != undefined){
    //   length = this.f.vehicleId.value.length ;
    // }
    // if(length!== this.selectedStaffs.filter(x => x.isDriver == true).length){
    //   this.toastr.warning('Vehicle Selected is ' +  length  + ' , driver selected is ' + this.selectedStaffs.filter(x => x.isDriver == true).length)
    //   return;
    // }


    // let selectedVehicles = [];
    // if(length > 0){
    //   (this.f.vehicleId.value).forEach(x =>{
    //     selectedVehicles.push({
    //       id : 0 ,
    //       vehicleId : x,
    //     })
    //   })
    // }

    let vehicleValidation : boolean = true;
    this.selectedStaffs.forEach(x =>{
      x.jobId = this.f.jobId.value;
      x.description = this.f.description.value;
      x.assignedFor = this.datePipe.transform(this.f.assignedFor.value,'yyyy-MM-dd');

      if(x.isDriver == false){
        x.vehicleId =0;
      }
      if(x.isDriver == true && (x.vehicleId == 0 || x.vehicleId == undefined || x.vehicleId == null)){
        vehicleValidation = false;
      }

    
      // x.jobVehicles = selectedVehicles;

    })



    if(!vehicleValidation){
      this.toastr.warning('Vehicle Should be Selected for Driver');
      return;
    }


    this.httpService.postApi('JobStaffMappings' , this.selectedStaffs).subscribe((resdata: any) =>{
      
      if(resdata.success ==  true){
      this.toastr.success('Assigned Successfully');
      if(this.mode == 2){
        this.modal.close(1);
      }
      this.getAssignedTeams();
      this.submitted = false;
      this.selectedStaffs = [];
      this.buildForm();
      this.isNew = false;

      }
    })
    
  }

  push(){

  }


  submitted : boolean = false;
  get f() { return this.masterForm.controls};

  buildForm(){

    if(this.mode ==  1){
      this.masterForm=this.fb.group({
        jobId  :[this.job.id ,Validators.required],
        teamId :[null, Validators.required],
        description  :[""],
        // vehicleId :[0],
        isTeamLeader  :[false, Validators.required],
        isDriver  :[false, Validators.required],
        submitBy :["1"],
        assignedFor :[this.datePipe.transform(new Date,'yyyy-MM-dd') , Validators.required],
        submitOn:[new Date]
      })

      this.getAssignedTeams();
    }else{
      this.masterForm=this.fb.group({
        jobId  :[null ,Validators.required],
        teamId :[this.team.id, Validators.required],
        description  :[""],
        // vehicleId :[0],
        isTeamLeader  :[false, Validators.required],
        isDriver  :[false, Validators.required],
        submitBy :["1"],
        assignedFor :[this.datePipe.transform(new Date,'yyyy-MM-dd') , Validators.required],
        submitOn:[new Date]
    }
      )

      this.onChange();
  }
  }


  onChange(){
    this.httpService.getApi('Teams/GetTeamStaffs/' + this.f.teamId.value).subscribe((resdata: any) =>{
     this.selectedStaffs = resdata.result;
    })
  }

  getTeams() {
    this.httpService.getApi('Teams/GetdlTeams').subscribe((resdata: any) =>{
      
     this.teamsList = resdata.result;
    })
  }


}
